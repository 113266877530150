@keyframes clouds {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-132.8125vw);
  }
}

.clouds {
  animation: clouds 30s linear infinite;
}