.root {
  width: 100vw;
  height: 843.75vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.image {
  width: 100vw;
  height: 56.25vw;
}