.button {
  cursor: pointer;
}

.button:active {
  transform: translate(4px, 4px);
}

.text {
  font-family: "Noto Sans SC", sans-serif;
  font-weight: 300;
  /* 24px */
  font-size: 1.25vw;
  line-height: 1.214em;
  color: #000000;
  text-align: justify;
}

.no {
  font-family: 'Alike Angular', serif;
  /* 48px */
  font-size: 2.5vw;
  text-align: right;
  line-height: 1em;
  color: #000000;
}

.lucky {
  font-family: 'Alike Angular', serif;
  /* 48px */
  font-size: 2.5vw;
  text-align: right;
  line-height: 1em;
  color: #EF7055;
}

@font-face {
  font-family: 'Alike Angular';
  src: url(assets/alike-angular-regular-subset.woff);
}

.textBg {
  border: 2px solid #000000;
  background-color: #FFFFFF;
}

.luckyBg {
  border: 2px solid #000000;
  /* 6px */
  box-shadow: -0.3125vw 0.3125vw 0 black;
}

.ballEnd {
  overflow: hidden;
}

.ball {
  pointer-events: none;
}

.click {
  pointer-events: all;
  cursor: inherit !important;
}